import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import AffectationCard from './AffectationCard';
import ContractResumeCard from './ContractResumeCard';
import LeaveCard from './LeaveCard';

const TabCleaning = (props) => {
  const url = `https://qc-test-ol.bubbleapps.io/agenda_compte?account_id=${props.record.id}`;
  return (
    <Grid container spacing={2}>
      <ContractResumeCard {...props} />
      <iframe
        src={url}
        scrolling="yes"
        frameBorder="0"
        width="100%"
        height="450"
        title="Agenda"
      iframe />
      <AffectationCard {...props} />
      <LeaveCard {...props} />
    </Grid>
  );
};

TabCleaning.propTypes = {
  record: PropTypes.object,
};

TabCleaning.defaultProps = {
  record: {},
};

export default TabCleaning;
