export const cleanerLevels = {
  ASP: 'ASP',
  ASC: 'ASC',
  ASCS: 'ASCS',
  AQS1: 'AQS1',
  AQS2: 'AQS2',
  AQS3: 'AQS3',
  ATQS1: 'ATQS1',
  ATQS2: 'ATQS2',
  ATQS3: 'ATQS3',
  CE1: 'CE1',
  CE2: 'CE2',
  CE3: 'CE3',
  MP1: 'MP1',
  MP2: 'MP2',
  MP3: 'MP3',
  MP4: 'MP4',
  MP5: 'MP5',
};

export const minHourlyRate = {
  [cleanerLevels.ASP]: {
    A: 12.13,
    B: 12.31,
  },
  [cleanerLevels.ASC]: {
    A: 12.17,
    B: 12.39,
  },
  [cleanerLevels.ASCS]: {
    A: 12.24,
    B: 12.46,
  },
  [cleanerLevels.AQS1]: {
    A: 12.30,
    B: 12.51,
  },
  [cleanerLevels.AQS2]: {
    A: 12.41,
    B: 12.64,
  },
  [cleanerLevels.AQS3]: {
    A: 12.52,
    B: 12.75,
  },
  [cleanerLevels.ATQS1]: {
    A: 12.76,
    B: 12.97,
  },
  [cleanerLevels.ATQS2]: {
    A: 13.48,
    B: 13.72,
  },
  [cleanerLevels.ATQS3]: {
    A: 14.49,
    B: 14.79,
  },
  [cleanerLevels.CE1]: 13.98,
  [cleanerLevels.CE2]: 14.79,
  [cleanerLevels.CE3]: 14.96,
  [cleanerLevels.MP1]: 15.03,
  [cleanerLevels.MP2]: 15.88,
  [cleanerLevels.MP3]: 17.61,
  [cleanerLevels.MP4]: 19.62,
  [cleanerLevels.MP5]: 21.21
};

export const isCleaner = (func) => {
  if (!func) {
    return false;
  }
  if (
    func === 'Cleaner' ||
    func === 'Glazier' ||
    func === 'Handyman' ||
    func === 'Team Leader'
  ) {
    return true;
  }
  return false;
};

export const isTeamLeader = (level) => {
  switch (level) {
    case 'CE1':
    case 'CE2':
    case 'CE3':
      return true;
    default:
      return false;
  }
};

export const computeMinRate = (level, classification) => {
  return isTeamLeader(level)
    ? minHourlyRate[level]
    : minHourlyRate[level][classification];
};
