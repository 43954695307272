import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const EchellonSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        { id: 'AQS1', name: 'AQS1'},
        { id: 'AQS3', name: 'AQS3'},
        { id: 'ASP', name: 'ASP'},
        { id: 'ASC', name: 'ASC'},
        { id: 'ASCS', name: 'ASCS'},
        { id: 'ATQS1', name: 'ATQS1'},
        { id: 'ATQS2', name: 'ATQS2'},
        { id: 'CE1', name: 'CE1'},
        { id: 'CE2', name: 'CE2'},
        { id: 'CE3', name: 'CE3'},
        { id: 'MP1', name: 'MP1'},
        { id: 'MP2', name: 'MP2'},
        { id: 'MP3', name: 'MP3'},
        { id: 'MP4', name: 'MP4'},
        { id: 'MP5', name: 'MP5'}
      ]}
    />
  );
};

EchellonSelectInput.propTypes = {
  validate: PropTypes.array,
  source: PropTypes.string.isRequired,
};

EchellonSelectInput.defaultProps = {
  validate: [],
};

export default EchellonSelectInput;
