import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { BorderTopSharp } from '@material-ui/icons';
import AffectationCard from './AffectationCard';
import CleanerContractCard from './CleanerContractCard';
import LeaveCard from './LeaveCard';
import ResumeCard from './ResumeCard';

const AffectationTab = ({ cleanerId, match, ...props }) => {
  const shouldUseTestVersion = true;
  const url = `https://qc-test-ol.bubbleapps.io/${shouldUseTestVersion ? 'version-test/' : ''}agenda_cleaner?cleaner_id=${props.record.id}`;

  const isSmall = useMediaQuery(theme => {
    return theme.breakpoints.down('sm');
  });

  const resizing = {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    resize: 'both',
    position: 'relative',
  };

  return (
    <Fragment>
      <ResumeCard {...props} />
      <br></br>

      <div style={resizing}>
        <iframe
          src={url}
          frameBorder="0"
          width="100%"
          height="600"
          title="Agenda"
        ></iframe>
      </div>

      <AffectationCard {...props} />

      <LeaveCard {...props} />
      {isSmall ? null : <CleanerContractCard {...props} />}
    </Fragment>
  );
};

AffectationTab.propTypes = {
  match: PropTypes.object,
  cleanerId: PropTypes.string,
  record: PropTypes.object,
};

AffectationTab.defaultProps = {
  match: {},
  cleanerId: '',
  record: {},
};

export default AffectationTab;
